<template>
    <v-container>
        <v-card>
            <v-card-title style="gap: 8px; line-height: 32px;">
                <NavButton />
                <h3 class="text-h5 font-weight-bold text-uppercase" style="color: var(--v-blueGrayMinsal-darken3); word-break: break-word;">
                    Refinanciamiento a procesos de compra de la unidad
                </h3>
            </v-card-title>
            <v-divider />
            
            <v-card-text>
                <h5 v-if="filtros.anio_fiscal" class="mt-4 text-subtitle-1 font-weight-bold" style="color: var(--v-blueGrayMinsal-darken3)">
                    Ejercicio {{ filtros.anio_fiscal.anio }}
                </h5>
        
                <v-row class="mt-4" align="center">
                    <v-col v-if="esAdministradorDinac" cols="12" md="6" lg="4">
                        <ProgresiveLoadAutocomplete
                            v-model="filtros.institucion"
                            :pageable="instituciones"
                            :loading="instituciones.isLoading"
                            :disabled="procesosCompra.isLoading"
                            :transform-item="mapearInstitucion"
                            class="flex-grow-1 flex-shrink-1"
                            label="Institución"
                            placeholder="Seleccione una institución"
                            itemText="nombre_completo"
                            itemValue="id"
                            hide-details
                            @search="buscarInstitucion"
                            @load-more="cargarMasInstituciones"
                            clearable
                        />
                    </v-col>

                    <v-col cols="12" md="6" lg="4">
                        <v-text-field
                            v-model="filtros.busqueda"
                            :disabled="procesosCompra.isLoading"
                            outlined
                            hide-details
                            clearable
                            placeholder="Código / Nombre de proceso"
                            label="Código / Nombre de proceso"
                        />
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                        <v-select 
                            v-model="filtros.modalidad_compra"
                            :disabled="procesosCompra.isLoading"
                            outlined
                            hide-details
                            clearable
                            :items="modalidadesCompra.data"
                            :loading="modalidadesCompra.isLoading"
                            placeholder="Modalidad de compra"
                            label="Modalidad de compra"
                            item-value="id"
                            item-text="nombre"
                        />
                    </v-col>
                    
                    <v-col cols="12" md="6" lg="4">
                        <v-select 
                            v-model="filtros.estado"
                            :disabled="procesosCompra.isLoading"
                            outlined
                            hide-details
                            clearable
                            :items="estadosProcesoCompra"
                            placeholder="Estado"
                            label="Estado"
                            item-value="id"
                            item-text="nombre"
                        />
                    </v-col>
        
                    <v-col cols="12" md="6" lg="4">
                        <v-select 
                            v-model="filtros.anio_fiscal"
                            :disabled="procesosCompra.isLoading"
                            outlined
                            clearable
                            hide-details
                            return-object
                            :items="aniosFiscales.data"
                            :loading="aniosFiscales.isLoading"
                            placeholder="Año fiscal"
                            label="Año fiscal"
                            item-value="id"
                            item-text="anio"
                        />
                    </v-col>
        
                    <v-col cols="12" lg="4">
                        <div class="d-flex align-center" style="gap: 16px;">
                            <v-btn 
                                x-large 
                                outlined 
                                :disabled="aniosFiscales.isLoading || modalidadesCompra.isLoading || procesosCompra.isLoading"
                                class="flex-grow-1 flex-shrink-1" 
                                color="primary"
                                @click.stop="limpiarFiltros"
                            >
                                Limpiar
                            </v-btn>

                            <v-btn 
                                x-large 
                                :disabled="aniosFiscales.isLoading || modalidadesCompra.isLoading"
                                :loading="procesosCompra.isLoading"
                                class="flex-grow-1 flex-shrink-1" 
                                color="primary"
                                @click.stop="buscarProcesos"
                            >
                                Buscar
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
        
                <h6 class="mt-12 text-subtitle-1 font-weight-bold" style="color: var(--v-blueGrayMinsal-darken3)">
                    Listado de procesos
                </h6>
        
                <DataTableComponent
                    no-gutters
                    dense
                    :headers="headers"
                    :items="procesosCompra.data"
                    :show_loading="procesosCompra.isLoading"
                    :total_registros="totalRows"
                    v-models:pagina="paginacion.page"
                    v-models:select="paginacion.per_page"
                    class="mt-2"
                    @paginar="paginarProcesosCompra"
                >
                    <template v-slot:item.institucion="{ item }">
                        {{ item.Institucion.codigo }} - 
                        {{ item.Institucion.nombre }}
                    </template>

                    <template v-slot:item.anio_fiscal="{ item }">
                        {{ item.ProcesoCompraPac?.AnioFiscal.anio ?? '-' }}
                    </template>
                    <template v-slot:item.codigo_interno="{ item }">
                        {{ item.codigo_interno?.trim() || '-' }}
                    </template>
                    <template v-slot:item.monto_planificado="{ item }">
                        ${{ formatearCantidadMonetaria(item.ProcesoCompraPac?.monto_planificado ?? item.suma_mercancia) }}
                    </template>
                    <template v-slot:item.monto_adjudicado="{ item }">
                        ${{ formatearCantidadMonetaria(item.monto_adjudicado) }}
                    </template>
                    <template v-slot:item.monto_modificado="{ item }">
                        ${{ formatearCantidadMonetaria(item.monto_modificado) }}
                    </template>
        
                    <template v-slot:item.forma_contratacion="{ item }">
                        {{ item.FormaContratacion.nombre }} ({{ item.version === 1 ? "v1" : "v2" }})
                    </template>
        
                    <template v-slot:item.estado="{ item }">
                        <template v-if="item?.proceso_padre">
                            <v-chip
                                v-if="item?.proceso_padre?.EstadoProceso.id == 1"
                                label
                                dark
                                :color="item?.proceso_padre?.SeguimientoProceso?.color"
                                class="mt-1"
                            >
                              {{ item?.proceso_padre?.SeguimientoProceso?.nombre || "" }}
                            </v-chip>
                            <v-chip
                                v-if="item?.proceso_padre?.EstadoProceso.id !== 1"
                                :color="procesoEstado(item?.proceso_padre?.EstadoProceso.id)"
                                label
                                class="mt-1 white--text"
                            >
                              {{ item?.proceso_padre?.EstadoProceso.nombre }}
                            </v-chip>
                            <p v-if="item?.proceso_padre?.EstadoProceso.id === 4 && item?.proceso_padre?.id_tipo_proceso === 2">
                              Este proceso está en construcción, en procesos con reserva
                            </p>
                          </template>
                          <template v-else>
                            <v-chip
                                v-if="item.EstadoProceso.id == 1"
                                label
                                dark
                                :color="item.SeguimientoProceso?.color"
                                class="mt-1"
                            >
                              {{ item.SeguimientoProceso?.nombre || "" }}
                            </v-chip>
                            <v-chip
                                v-if="item.EstadoProceso.id !== 1"
                                :color="procesoEstado(item.EstadoProceso.id)"
                                label
                                class="mt-1 white--text"
                            >
                              {{ item.EstadoProceso.nombre }}
                            </v-chip>
                            <p v-if="item.EstadoProceso.id === 4 && item.id_tipo_proceso === 2">
                              Este proceso está en construcción, en procesos con reserva
                            </p>
                          </template>
                    </template>
        
                    <template v-slot:item.acciones="{ item }">
                        <div class="d-flex align-center justify-center" style="gap: 4px;">
                            <v-btn icon :to="`/refinanciamiento-compras/vinculaciones/${item.id}`">
                                <v-icon color="secondary" x-small>mdi-eye</v-icon>
                            </v-btn>
                            <v-btn icon :to="`/refinanciamiento-compras/refinanciar/${item.id}`">
                                <v-icon color="secondary">mdi-currency-usd</v-icon>
                            </v-btn>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn 
                                        v-on="on" 
                                        v-bind="attrs"
                                        v-if="esUsuarioUfi" 
                                        icon 
                                        :to="`/refinanciamiento-compras/certificar/${item.id}`"
                                    >
                                        <v-icon :color="obtenerColorCertificacion(item.monto_modificado, item.monto_certificado)">
                                            mdi-certificate
                                        </v-icon>
                                    </v-btn>
                                    <v-icon 
                                        v-else 
                                        v-on="on"
                                        v-bind="attrs"
                                        :color="obtenerColorCertificacion(item.monto_modificado, item.monto_certificado)"
                                    >
                                        mdi-certificate
                                    </v-icon>
                                </template>
                                <span>{{ obtenerTextoCertificacion(item.monto_modificado, item.monto_certificado) }}</span>
                            </v-tooltip>
                        </div>
                    </template>
                </DataTableComponent>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import { 
    createLoadable, 
    createPageable, 
    setLoadableResponse, 
    setPageableResponse, 
    toggleLoadable, 
    togglePageable, 
    isResponseSuccesful 
} from '@/utils/loadable';
import ProgresiveLoadAutocomplete from '@/components/utils/ProgresiveLoadAutocomplete.vue';
import DataTableComponent from '@/components/DataTableComponent.vue';
import NavButton from '@/components/utils/NavButton.vue';
import { toFixed } from '@/utils/data';
import { isNil } from 'lodash';

const filtrosIniciales = {
    busqueda: null,
    modalidad_compra: null,
    estado: null,
    anio_fiscal: null,
    institucion: null,
}

export default {
    name: 'RefinanciamientoCompras',
    components: { DataTableComponent, NavButton, ProgresiveLoadAutocomplete },
    data: () => ({
        // Data para los filtros
        aniosFiscales: createLoadable([]),
        modalidadesCompra: createLoadable([]),
        estadosProcesoCompra: [
            // { id: 1, nombre: "Configuración proceso" },
            // { id: 11, nombre: "Recepción de solicitudes" },
            { id: 3, nombre: "En ejecución" },
            { id: 5, nombre: "Contratación" },
        ],
        // Modelos para los filtros
        filtros: { ...filtrosIniciales },
        // Data
        procesosCompra: createPageable([], 10),
        paginacion: {
            per_page: 10,
            page: 1,
        },
        // Filtro institucion
        instituciones: createPageable([], 50),
        filtroInstitucion: '',
        paginacionInstituciones: {
            page: 1,
            per_page: 50,
        },
    }),
    computed: {
        esAdministradorDinac() {
            return this.haveRoles(['ROLE_REFINANCIAR_PROCESO_COMPRA_ADMINISTRADOR_DINAC']);
        },
        headers() {
            const tableHeaders = [
                { text: 'Año fiscal', value: 'anio_fiscal', align: 'center', sortable: false },
                this.esAdministradorDinac ? { text: 'Institución', value: 'institucion', align: 'center', sortable: false } : null,
                { text: 'Código', value: 'codigo_proceso', align: 'center', sortable: false },
                { text: 'Código interno', value: 'codigo_interno', align: 'center', sortable: false },
                { text: 'Proceso', value: 'nombre_proceso', align: 'center', sortable: false },
                { text: 'Modalidad de compra', value: 'forma_contratacion', align: 'center', sortable: false },
                { text: 'Monto planificado ($)', value: 'monto_planificado', align: 'center', sortable: false },
                { text: 'Monto adjudicado ($)', value: 'monto_adjudicado', align: 'center', sortable: false },
                { text: 'Monto modificado ($)', value: 'monto_modificado', align: 'center', sortable: false },
                { text: 'Estado', value: 'estado', align: 'center', sortable: false },
                { text: 'Acciones', value: 'acciones', align: 'center', sortable: false },
            ];

            return tableHeaders.filter((tableHeader) => !isNil(tableHeader));
        },
        totalRows() {
            return this.procesosCompra.pagination.total_rows;
        },
        esUsuarioUfi() {
            return this.haveRoles(['ROLE_UFI']);
        },
    },
    methods: {
        formatearCantidadMonetaria(valor) {
            const config = { currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 8 };
            
            return Intl.NumberFormat("en-US", config).format(valor ?? 0)
        },
        procesoEstado(id) {
            switch (id) {
                case 5:
                return "error";
                case 6:
                return "amber lighten-3";
                case 7:
                return "purple lighten-2";
                case 8:
                return "info";
                default:
                break;
            }
        },
        nombreEstado(id) {
            switch (id) {
                case 5:
                return "Proceso desierto";
                case 6:
                return "Proceso suspendido";
                case 8:
                return "Proceso cancelado";
                default:
                break;
            }
        },
        paginarProcesosCompra(update) {
            this.paginacion = {
                page: update.pagina,
                per_page: update.cantidad_por_pagina,
            }
        },
        obtenerPorcentajeCertificado(montoTotal, montoCertificado) {
            if (!montoTotal || !montoCertificado) return 0;

            return toFixed((montoCertificado * 100) / montoTotal);
        },
        obtenerColorCertificacion(montoTotal, montoCertificado) {
            const porcentaje = this.obtenerPorcentajeCertificado(montoTotal, montoCertificado);

            return porcentaje === 100 ? '#57cc99 !important' : 'secondary';
        },
        obtenerTextoCertificacion(montoTotal, montoCertificado) {
            const porcentaje = this.obtenerPorcentajeCertificado(montoTotal, montoCertificado);

            return porcentaje === 100 ? 'Fondos certificados' : `Pendiente de certificación (${porcentaje.toFixed(2)}%)`;
        },
        // Carga de data de filtros
        async cargarAniosFiscales() {
            try {
                toggleLoadable(this.aniosFiscales);
                const { data } = await this.services.PacProcesos.cargarAniosFiscales();
                setLoadableResponse(this.aniosFiscales, { data });
            } finally {
                this.aniosFiscales.isLoading = false;
            }
        },
        async cargarModalidadesCompra() {
            try {
                toggleLoadable(this.modalidadesCompra);
                const { data } = await this.services.Paac.getModalidades({ compra_tradicional: true });
                setLoadableResponse(this.modalidadesCompra, { data: data?.forma_contratacion || [] });
            } finally {
                this.modalidadesCompra.isLoading = false;
            }
        },
        async buscarInstitucion(termino) {
            if (this.filtroInstitucion === termino) return;
            
            this.filtroInstitucion = termino;
            this.instituciones.data = [];
            this.paginacionInstituciones.page = 1;
            this.cargarInstituciones();
        },
        async cargarMasInstituciones() {
            if (this.instituciones.isLoading) return;

            const { page, per_page, total_rows } = this.instituciones.pagination;
            const currentlyLoaded = page * per_page;

            if (!(currentlyLoaded < total_rows)) return;

            this.paginacionInstituciones.page = page + 1;
            this.paginacionInstituciones.per_page = per_page;
            this.cargarInstituciones();
        },
        async cargarInstituciones() {
            const filtros = {
                pagination: true,
                per_page: this.paginacionInstituciones.per_page,
                page: this.paginacionInstituciones.page,
                busqueda: this.filtroInstitucion,
            };

            togglePageable(this.instituciones);
            const copiaInstituciones = this.instituciones.data;
            const { data, headers } = await this.services.RefinanciamientoCompras.cargarInstituciones(filtros);
            setPageableResponse(this.instituciones, data, headers, { skipOnSuccess: true, persistDataOnError: true });

            if (!isResponseSuccesful(data)) {
                // Hacemos rollback de la pagina en caso de error para volver a cargar la misma al scrollear
                const { page } = this.paginacionInstituciones;
                this.paginacionInstituciones.pagina = page > 1 ? page - 1 : 1;
                return;
            }

            this.instituciones.data = copiaInstituciones.concat(data.data);
        },
        mapearInstitucion(institucion) {
            return ({ ...institucion, nombre_completo: `${institucion.codigo} - ${institucion.nombre}` });
        },
        //  Manipulacion filtros
        limpiarFiltros() {
            // Limpiar filtro institucion 
            this.filtroInstitucion = '';
            this.paginacionInstituciones = { ...this.paginacionInstituciones, page: 1 };
            this.instituciones = createPageable([], 30);

            if (this.esAdministradorDinac) {
                this.cargarInstituciones();
            }

            this.filtros = { ...filtrosIniciales };
            this.paginacion = {
                ...this.paginacion,
                page: 1,
            };
        },
        buscarProcesos() {
            this.paginacion = {
                ...this.paginacion,
                page: 1,
            };
        },
        // Data
        async cargarProcesosCompra() {
            togglePageable(this.procesosCompra);

            const { data, headers } = await this.services.RefinanciamientoCompras.cargarProcesosCompra({
                pagination: true,
                ...this.paginacion,
                ...this.filtros,
                anio_fiscal: this.filtros.anio_fiscal?.id,
            });

            setPageableResponse(this.procesosCompra, data, headers);
        },
    },
    watch: {
        paginacion: {
            handler() {
                this.cargarProcesosCompra();
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        this.cargarAniosFiscales();
        this.cargarModalidadesCompra();

        if (this.esAdministradorDinac) this.cargarInstituciones();
    },
}
</script>